<template>
  <div class="contract-list">
    <div v-if="userLogined" class="box">
      <div v-if="list.length">
        <div
          v-for="(item,index) in list"
          :key="item.id"
          class="contract-time"
          :class="{active: curContractId===item.id, mg: (index+1)%2 !==1}"
          @click="clickHandler(item)"
        >
          <div class="period">
            <span>{{ item.timeVal }}{{ unitMap[item.timeUnit] }}</span>
          </div>
          <div class="profit">
            <span>{{ (item.profitRatio).toFixed(2) }}%</span>
          </div>
          <span class="el-icon-check"></span>
        </div>
      </div>
      <div v-else class="color666" style="padding-top:120px;">{{ $t(255) }}</div>
    </div>
    <span v-else class="no-msg">
      <el-button type="text" @click="login">{{ $t(94) }}</el-button><span>{{ $t(267) }}</span>
    </span>
  </div>
</template>

<script>
import { getContractList } from '@/api'
import utils from '@/utils/misc'
import tradingMixin from '@/mixins/tradingMixin'
export default {
  name: 'ContractTime',
  mixins: [tradingMixin],
  components: {
  },
  props: {
    value: Object
  },
  data() {
    return {
      curContractId: '',
      list: [],
      unitMap:{
        1: 'S',
        2: 'M',
        3: 'H',
        4: 'D'
      }
    }
  },
  computed: {
    // proId() {
    //   return this.$store.state.common.proData.id
    // },
    userLogined() {
      return utils.getLs('token')
    }
  },
  watch: {
    curCoinName(val) {
      this.getContractList()
    }
  },
  mounted() {
    this.getContractList()
  },
  methods: {
    getContractList() {
      if (!this.userLogined) return
      const obj = this.futureMap[`${this.curCoinName}USDT`]
      getContractList(obj.pairId).then(res => {
        this.list = res.contracts || []
        if (this.list.length) {
          this.curContractId = this.list[0].id
          this.$emit('input', this.list[0])
        } else {
          this.$emit('input', {})
        }
      })
    },
    clickHandler(item) {
      this.curContractId = item.id
      this.$emit('input', item)
    },
    login() {
      this.$router.push({ name: 'Login', query: { redirect: this.$route.name }})
    }
  }
}
</script>

<style lang="scss" scoped>
  .contract-list{
    position: relative;
    padding: 0px 20px;
    text-align: center;
    height: 274px;
    min-width: 300px;
    .no-msg{
      margin-top: 100px;
      display: inline-block;
      font-size: 12px;
    }
  }
  .box{
    width: 280px;
    height: 275px;
    overflow-y: auto;
  }
  .contract-time{
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    width: 100px;
    overflow: hidden;
    cursor: pointer;
    margin-top: 14px;
    opacity: .3;
    color: #fff;
    &.mg{
      margin-left: 30px;
    }
    position: relative;
    .el-icon-check{
      position: absolute;
      display: none;
      right: 5px;
      top: 5px;
      color: #7cf5df;
      font-size: 16px;
      font-weight: 800;
    }
    .period{
      background-color:$primaryColor;
      font-size: 14px;
      padding: 14px;
    }
    .profit{
      font-size: 12px;
      background-color:#f1f1f1;
      padding: 14px;
      color: #333;
    }
    &.active{
      color: #fff;
      opacity:1;
      .el-icon-check{
        display: block;
      }
    }
  }
</style>
