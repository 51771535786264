<template>
  <div class="contract trading-board bg-primary1">
    <SimpleHeader></SimpleHeader>
    <TradingLayout
      :action="10003"
      :realTimeAction="10008">
      <div slot="trading" class="trade-box flex1">
        <el-tabs>
          <el-tab-pane :label="changeText ? $t('nav3_2') : $t('nav3')">
            <div class="flex align-center">
              <ContractTime
                v-model="contract"
              ></ContractTime>
              <div class="spliter"></div>
              <el-form
                ref="form"
                :rules="rules"
                :model="form"
                class="flex1 form"
              >
                <div v-if="!showCommonSuccess">
                  <div class="pro-msg flex">
                    <div class="flex align-center flex1"><span class="label">{{ $t(266) }}</span><span class="title">{{ futureMap[curCoinName+'USDT'].alias || curCoinName }}</span></div>
                    <div class="flex align-center flex1">
                      <span class="label">{{ $t(250) }}</span>
                      <span class="price" :style="{color: curCoinMsg.change>=0?riseColor: fallColor}">
                        {{ curCoinMsg.price }}</span> USDT
                    </div>
                  </div>
                  <div class="direction">
                    <span :class="{active: form.direction===1}" @click="form.direction=1">{{ !isDAEDALUS ? $t(264) :$t(2641) }}</span>
                    <span :class="{active: form.direction===2}" @click="form.direction=2">{{ !isDAEDALUS ? $t(265) :$t(2651) }}</span>
                  </div>
                  <el-form-item
                    prop="amount"
                    class="amount"
                  >
                    <el-input v-model="form.amount"></el-input>
                    <span class="label">{{ $t(268) }}</span>
                    <span class="max" @click="setMax">{{ $t(216) }}</span>
                  </el-form-item>
                  <div class="flex balance">
                    <span class="align-center">{{ $t(193) }}: {{ usdtBalance }}USDT <span class="el-icon-sort primary cursor" @click="$router.push({name: 'Transfer'})">{{$t(188)}}</span><span style="margin-left: 20px;">{{ $t(271) }}{{ contract.minAmount || '--' }} (USDT)</span></span>
                    <span v-if="!!+configObj.futureTradingFee">{{ $t(270) }}:{{ configObj.futureTradingFee }}%</span>
                  </div>
                </div>
                <div v-else class="success-modle flex align-center">
                  <div v-if="successWithProgress" class="flex1 flex p-wrap">
                    <el-progress ref="progress" :show-text="false" type="circle" :percentage="timer.percent" :color="isWon?riseColor: fallColor" :width="160" :stroke-width="14" stroke-linecap="butt"></el-progress>
                    <span v-if="successOrderDetail.status===1" class="left-time">{{ timer.leftTime }}S</span>
                    <span v-else class="left-time">{{ $t(274) }}</span>
                  </div>
                  <div v-else class="flex1 flex p-wrap">
                    <p>
                      <i class="el-icon-finished"></i>
                      <span>{{ $t(275) }}</span>
                    </p>
                  </div>
                  <div class="flex1 pro-msg">
                    <p><span class="label">{{ $t(276) }}</span><span class="title">{{ futureMap[curCoinName+'USDT'].alias ||curCoinName }}</span></p>
                    <p><span class="label">{{ $t(32) }}</span><span class="value">
                      {{ successOrderDetail.buyDirection===1 ? $t(264) : $t(265) }}</span></p>
                    <p><span class="label">{{ $t(250) }}</span>
                      <span class="value">
                        {{ curCoinMsg.price }}</span></p>
                    <p><span class="label">{{ $t(278) }}</span>
                    <span class="value">
                        {{ successOrderDetail.openingPrice }}</span></p>
                    <p><span class="label">{{ $t(279) }}</span>
                      <span class="value">
                        {{ successOrderDetail.closingPrice||'--' }}</span></p>
                    <p><span class="label">{{ $t(272) }}</span>
                      <span class="value">
                        {{ +successOrderDetail.status === 1 ? $t(273):$t(274) }}</span></p>
                    <p><span class="label">{{ $t(283) }}</span>
                      <span v-if="successOrderDetail.buyDirection === 1" class="value" :style="{color: (+curCoinMsg.price - +successOrderDetail.openingPrice)>=0?riseColor: fallColor}">
                        <span v-if="successOrderDetail.status === 1">
                          {{ (+curCoinMsg.price - +successOrderDetail.openingPrice)>0 ? (+contract.profitRatio*successOrderDetail.buyAmount/100).toFixed(4) : -successOrderDetail.buyAmount }}
                        </span>
                        <span v-else :style="{color: (+successOrderDetail.closingPrice - +successOrderDetail.openingPrice)>=0?riseColor: fallColor}">{{ (+successOrderDetail.winAmount-successOrderDetail.buyAmount).toFixed(4) }}</span>
                      </span>
                      <span v-else class="value" :style="{color: (+curCoinMsg.price - +successOrderDetail.openingPrice)<0?riseColor: fallColor}">
                        <span v-if="successOrderDetail.status === 1">
                          {{ ((+successOrderDetail.openingPrice - +curCoinMsg.price) >0) ? (+contract.profitRatio*successOrderDetail.buyAmount/100).toFixed(4):-successOrderDetail.buyAmount }}
                        </span>
                        <span v-else :style="{color: (+successOrderDetail.closingPrice - +successOrderDetail.openingPrice)<0?riseColor: fallColor}">{{ (+successOrderDetail.winAmount-successOrderDetail.buyAmount).toFixed(4) }}</span>
                      </span>
                    </p>
                  </div>
                </div>
                <el-form-item>
                  <el-button v-if="userLogined" type="primary" :disabled="loading || !contract.id" @click="confirmSubmit">{{ showCommonSuccess ? $t(280):$t(284) }}</el-button>
                  <el-button v-else type="primary" @click="$router.push({name: 'Login', query:{redirect: '/futures'}})">
                    <span type="text">{{ $t(94) }}</span>
                    <span class="color-normal"> / </span>
                    <span type="text">{{ $t(95) }}</span>
                  </el-button>
                </el-form-item>
              </el-form>

            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </TradingLayout>
    <Footer></Footer>
  </div>
</template>

<script>
import TradingLayout from './components/TradingLayout'
import ContractTime from './components/ContractTime'
import tradingMixin from '@/mixins/tradingMixin'
import SimpleHeader from '@/components/SimpleHeader'
import { buyFuture, getFutureOrderDetail } from '@/api'
import Footer from '@/components/footer/Footer'
import utils from '@/utils/misc'
export default {
  name: 'Contract',
  components: {
    TradingLayout,
    ContractTime,
    Footer,
    SimpleHeader
  },
  mixins: [tradingMixin],
  data() {
    return {
      curCate: 'FUTURE',
      contractList: [],
      form: {
        amount: '',
        direction: 1,
        tradingId: ''
      },
      rules: {
        amount: [
          { required: true, message: this.$t(269) },
          { trigger: 'blur', validator: (rule, value, callback) => {
            if (isNaN(value) || value < 0) {
              return callback(new Error(this.$t(210)))
            }
            if (this.contract.minAmount && value < +this.contract.minAmount) {
              return callback(new Error(this.$t(271) + this.contract.minAmount))
            }
            if (+value > +this.usdtBalance) {
              return callback(new Error(this.$t(235)))
            }
            callback()
          } }
        ]
      },
      contract: {
        minAmount: '',
        value: 0,
        id: ''
      },
      loading: false,
      successWithProgress: false,
      showCommonSuccess: false,
      successOrderDetail: {
        status: 1,
        openingPrice: '--',
        buyDirection: 1,
        winAmount: 0
      },
      timer: {
        percent: 0,
        id: null,
        leftTime: ''
      },
      refreshTimer: null,
      refreshCount: 0
    }
  },
  computed: {
    changeText() {
      return [
        'c1241e1d-ad79-487b-b6f4-fb9d653ddf22', // test
        '3b042e72-f85f-4376-a138-ba1950da8fee', // prepro
        '7b89bc95-1758-46ef-8b9b-869892ee8f67', // res
        '9d28bfe3-6392-411e-b821-ede06d6354a5'
      ].includes(utils.getLs('agentIdentify'))
    },
    curCoinMsg(){
      return this.coinPriceMap[this.curCoinName+'USDT'] || {}
    },
    userLogined() {
      return utils.getLs('token')
    },
    configObj() {
      return window.configObj
    },
    isWon() {
      if (!this.successWithProgress) return true
      if (this.form.direction === 1) {
        return this.successOrderDetail.openingPrice < this.coinPriceMap[this.curCoinName+'USDT'].price
      } else {
        return this.successOrderDetail.openingPrice > this.coinPriceMap[this.curCoinName+'USDT'].price
      }
    },
    isDAEDALUS() {
      return utils.getLs('agentIdentify') === 'a6fc185d-1b4e-4f94-8831-0612ad9c2f8c'
    },
    usdtBalance() {
      let balance = 0
      if (this.curAssets.coins instanceof Array) {
        for (const item of this.curAssets.coins) {
          if (item.coin === 'USDT') {
            balance = item.availableAmount
            break
          }
        }
      }
      return balance
    },
  },
  watch: {
    successWithProgress(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs.progress) {
            this.$refs.progress.$el.querySelector('path').setAttribute('stroke', 'rgba(37, 42, 68, 0.5)')
          }
        })
      }
    }
  },
  beforeMount() {
  },
  mounted() {
    if (this.$refs.progress) {
      this.$refs.progress.$el.querySelector('path').setAttribute('stroke', 'rgba(37, 42, 68, 0.5)')
    }
  },
  methods: {
    setMax() {
      if (+this.usdtBalance === 0) return
      this.form.amount = this.usdtBalance
    },
    refreshOrderDetail(id) {
      getFutureOrderDetail(id).then(res => {
        if (res.status === 1 && this.refreshCount < 5) {
          this.refreshTimer = setTimeout(() => {
            this.refreshCount++
            this.refreshOrderDetail(id)
          }, 1000)
        } else {
          this.successOrderDetail = res
          clearTimeout(this.refreshTimer)
        }
      })
    },
    confirmSubmit() {
      if (this.showCommonSuccess) {
        this.showCommonSuccess = false
        if (this.successWithProgress) {
          this.successWithProgress = false
          clearInterval(this.timer.id)
          this.timer = {
            percent: 0,
            id: null,
            leftTime: ''
          }
        }
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          buyFuture({
            amount: +this.form.amount,
            direction: this.form.direction,
            contractId: this.contract.id,
            pair: `${this.curCoinName}USDT`,
            alias: this.futureMap[this.curCoinName+'USDT'].alias
          }).then(res => {
            this.form.amount = ''
            this.$store.dispatch('getUserAssets')
            this.showCommonSuccess = true
            const id = res.orderId
            utils.emit('updateList')
            if (this.timer.id) {
              clearInterval(this.timer.id)
            }
            getFutureOrderDetail(id).then(res => {
              this.successOrderDetail = res
              if (this.contract.timeUnit === 1 && this.contract.timeVal <= 120) {
                this.successWithProgress = true
                this.timer.leftTime = this.contract.timeVal
                const per = 100 / this.contract.timeVal
                this.timer.id = setInterval(() => {
                  if (this.timer.leftTime) {
                    this.timer.percent = this.timer.leftTime === 1 ? 100 : this.timer.percent + per
                    this.timer.leftTime--
                  } else {
                    this.refreshOrderDetail(id)
                    clearInterval(this.timer.id)
                    utils.emit('updateList')
                    this.$store.dispatch('getUserAssets')
                  }
                }, 1000)
              }
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.trading-board{
  .block{
    padding-left: 10px;
  }
  .pro-msg{
    padding: 10px 0px;
    .label{
      font-size: 12px;
      margin-right: 10px;
    }
    .title,.price{
      font-size: 16px;
      font-weight: 800;
    }
    .flex:not(:first-child){
      margin-left: 20px;
    }
  }
  .form{
    padding: 0px 20px;
    .balance{
      font-size: 12px;
      margin: 20px 0px 18px 0px;
      span{
        &:nth-last-child(1){
          flex: 1;
          text-align: right;
        }
      }
    }
    .success-modle{
      p{
        line-height: 28px;
        .value{
          font-size: 12px;
          font-weight: normal;
        }
      }
      .p-wrap{
        margin: 10px;
        position: relative;
        .left-time{
          position: absolute;
          color: #333;
          font-weight: 700;
          font-size: 15px;
          left: 51px;
          top: 71px;
          display: inline-block;
          width: 60px;
          text-align: center;
        }
        .el-progress{
          height: 180px;
          width: 180px;
        }
      }
    }
    .el-form-item{
      margin-bottom: 4px;
    }
    .amount{
      position: relative;
      span{
        position: absolute;
        // color: #fff;
      }
      .label{
        left: 10px;
        top: 0px;
        color: #666;
      }
      .max{
        right: 10px;
        top: 0px;
        cursor: pointer;
        &:hover{
          opacity: .8;
        }
      }
      .el-input__inner{
        padding-left: 140px;
      }
    }
  }
  .direction{
    display: flex;
    span{
      display: inline-block;
      flex:1;
      cursor: pointer;
      border-radius: 3px;
      &:not(:first-child){
        margin-left: 20px;
      }
      padding: 8px;
      text-align: center;
      border: 1px solid $borderColor;
      color: #999;
      &.active{
        border-color: #00d3ad;
        color: #00d3ad;
        font-weight: 800;
      }
    }
    margin: 10px 0px 20px 0px;
  }
  .el-tabs__header{
    padding: 4px;
  }
  .el-tabs__nav{
    padding-left: 10px;
  }
  .el-tabs__header{
    margin-bottom: 0px;
  }
  .el-tabs__header .el-tabs__active-bar{
    left: 10px;
  }
}
</style>
